export const DISTRIBUTED_EXPENSE_TYPES = {
  energy_label: 'Energimærkning',
  distribution_statement: 'Forbrugsregnskab',
  caretaker: 'Vicevært',
  snow_removal: 'Snerydning',
  cleaning_of_common_areas: 'Rengøring af fællesarealer',
  graffiti_removal: 'Fjernelse af graffiti',
  insurances: 'Forsikringer',
  guard_and_surveillance: 'Vagt og overvågning',
  administration: 'Administration',
  submeters: 'Fast årligt bidrag - bimålere',
  renovation: 'Renovation',
  electricity: 'Fælles el',
  eed_including_online_usage_overview: 'EED inkl. online forbrugsoversigt',
}
