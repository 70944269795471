import random from 'lodash/random'
import range from 'lodash/range'
import dayjs from 'dayjs'
import { getName, getEmailFromName, getRandom } from '../../fake'
import { wrapResponse } from './utils'

export function generateUser({ id, name, email }) {
  name = name || getName()
  email = email || getEmailFromName(name)
  const activationDate = getRandom([
    null,
    dayjs().subtract(random(1, 700), 'days').toISOString(),
  ])
  return {
    attributes: {
      'activated-at': activationDate,
      email,
      name,
    },
    id: id.toString(),
    type: 'users',
  }
}

export default function generateUsers({ count = 5, usersArray = [] }) {
  const existingUsers = usersArray.map(({ name, email }, index) =>
    generateUser({ id: index + 1, name, email })
  )
  const extraUsers = (
    usersArray.length < count ? range(usersArray.length + 1, count + 1) : []
  ).map((id) => generateUser({ id }))
  return wrapResponse({
    data: existingUsers.concat(extraUsers),
  })
}
