import dayjs from 'dayjs'
import padStart from 'lodash/padStart'
import range from 'lodash/range'
import random from 'lodash/random'
import {
  getApartmentIdentifier,
  getCity,
  getFirstName,
  getName,
  getPhoneNumber,
  getStreet,
  getWithProbability,
} from '../../fake'
import { generateTenant, generateEmailSettings } from './tenants'
import { wrapResponse } from './utils'

export function generateAlarmStatus({
  id,
  type = 'ventilation',
  status = 'ok',
}) {
  return {
    id: id,
    type: 'alarm-statuses',
    attributes: {
      'alarm-type': type,
      status,
    },
  }
}

export function generateTenancy({ id, hasExternalInfo }) {
  return {
    id: id,
    type: 'tenancies',
    attributes: {
      number: 1,
      'move-in-date': `${random(1995, 2017)}-${padStart(
        random(1, 12).toString(),
        2,
        '0'
      )}-01`,
      // Give all even tenancy ids a move-out-date, for deterministic testing
      'move-out-date':
        Number(id) % 2 === 0
          ? dayjs()
              .add(random(2, 6), 'months')
              .endOf('month')
              .format('YYYY-MM-DD')
          : null,
    },
    relationships: {
      tenant: {
        data: {
          // Reuse tenancy id for tenant id, fine for mocking purposes
          id,
          type: 'accounts',
        },
      },
      household: {
        data: {
          // Reuse tenancy id for household id, fine for mocking purposes
          id,
          type: 'households',
        },
      },
      ...(hasExternalInfo && {
        'external-tenancies': {
          data: [
            {
              // Reuse tenancy id for external-tenancy id, fine for mocking purposes
              id: id,
              type: 'external-tenancies',
            },
          ],
        },
      }),
    },
  }
}

export function generateHousehold({
  id,
  number,
  apartment,
  addressId,
  alarmStatusIdsArray = [],
  hasExternalInfo = false,
}) {
  return {
    id: id,
    type: 'households',
    attributes: {
      number,
      'standard-dk-sort': number,
      apartment,
      virtual: false,
      business: false,
      taxable: false,
    },
    relationships: {
      'alarm-statuses': {
        data: alarmStatusIdsArray.map((id) => ({
          id: id,
          type: 'alarm-statuses',
        })),
      },
      address: {
        data: {
          id: addressId,
          type: 'addresses',
        },
      },
      tenancies: {
        data: [
          {
            // Reuse household id for tenancy id, fine for mocking purposes
            id,
            type: 'tenancies',
          },
        ],
      },
      tenant: {
        data: {
          // Reuse household id for tenant id, fine for mocking purposes
          id,
          type: 'accounts',
        },
      },
      ...(hasExternalInfo && {
        'external-households': {
          data: [
            {
              // Reuse household id for external-households id, fine for mocking purposes
              id: id,
              type: 'external-households',
            },
          ],
        },
      }),
    },
  }
}

export function generateAddress({
  id,
  streetAndNumber,
  city,
  zip,
  propertyId,
  householdIdsArray = [],
}) {
  return {
    id: id,
    type: 'addresses',
    attributes: {
      'street-and-number': streetAndNumber,
      // Add a place to a single address for testing purposes
      place: id === '300' ? `Nedre ${getCity()}` : null,
      city,
      'zip-code': zip,
      'country-code-alpha3': 'DNK',
    },
    relationships: {
      property: {
        data: {
          id: propertyId,
          type: 'properties',
        },
      },
      households: {
        data: householdIdsArray.map((id) => ({
          id,
          type: 'households',
        })),
      },
    },
  }
}

export function generateProperty({
  id,
  name,
  addressIdsArray = [],
  zip,
  city,
}) {
  return {
    id: id,
    type: 'properties',
    attributes: {
      name,
      // Add a legal name to a single property for testing purposes
      'legal-name':
        id === '300'
          ? `Ejerforeningen ${getFirstName().replace(/s$/, '')}sminde`
          : null,
      number: Number(id),
      'postal-address': {
        address: name,
        city: city,
        'zip-code': zip,
        'country-code-alpha3': 'DNK',
      },
    },
    relationships: {
      addresses: {
        data: addressIdsArray.map((id) => ({
          id,
          type: 'addresses',
        })),
      },
      // Property 100 and 200 are the ones that are fake-synchronized from Unik
      // in demo mode, and thus should have external-properties
      ...((id === '100' || id === '200') && {
        'external-properties': {
          data: [
            {
              // Reuse property id for external-properties, fine for mock purposes
              id,
              type: 'external-properties',
            },
          ],
        },
      }),
    },
  }
}

export function generateExternalInfo({ id, type, companyNumber }) {
  return {
    id: id,
    type,
    attributes: {
      synced: true,
      number: (Number(id) + 150).toString(),
      ...(companyNumber && { companyNumber }),
      system: 'unik',
      'last-synced-at': dayjs()
        .subtract(random(1, 12), 'hours')
        .toDate()
        .toISOString(),
    },
  }
}

export default function generateProperties({ count = 0 }) {
  let properties = []
  let addresses = []
  let households = []
  let tenants = []
  let tenancies = []
  let emailSettings = []
  let alarmStatuses = []
  let externalProperties = []
  let externalHouseholds = []
  let externalTenancies = []

  // Generate properties, addresses, households, tenants, email settings and alarm statuses
  range(1, count + 1)
    // Three digit property ids
    .map((n) => n * 100)
    .forEach((propertyNumericId) => {
      const propertyStringId = propertyNumericId.toString()
      const propertyName = `${getStreet()} ${random(7, 150)}`
      const propertyCity = getCity()
      const propertyZip = (random(11, 90) * 100).toString()

      // E.g. first household in property id 500 has id 501 etc.
      const getHouseholdStringId = (householdNumericId) =>
        (propertyNumericId + householdNumericId).toString()

      // Ensure an even number of households
      const householdCount = random(2, 5) * 2
      const householdNumericIds = range(1, householdCount + 1)

      properties.push(
        generateProperty({
          id: propertyStringId,
          name: propertyName,
          // Reuse the property id for address, fine for test purposes
          addressIdsArray: [propertyStringId],
          zip: propertyZip,
          city: propertyCity,
        })
      )

      addresses.push(
        generateAddress({
          // Reuse the property id for address, fine for test purposes
          id: propertyStringId,
          streetAndNumber: propertyName,
          city: propertyCity,
          zip: propertyZip,
          propertyId: propertyStringId,
          householdIdsArray: householdNumericIds.map(getHouseholdStringId),
        })
      )

      // Property 100 and 200 are the ones that are fake-synchronized from Unik
      // in demo mode, and thus should have external-properties
      if (propertyNumericId === 100 || propertyNumericId === 200) {
        externalProperties.push(
          generateExternalInfo({
            // Reuse the property id for external info, fine for test purposes
            id: propertyStringId,
            type: 'external-properties',
            companyNumber: (propertyNumericId + 50).toString(),
          })
        )
      }

      householdNumericIds.forEach((householdNumericId) => {
        const householdStringId = getHouseholdStringId(householdNumericId)

        households.push(
          generateHousehold({
            id: householdStringId,
            number: householdNumericId,
            // Reuse the property id for address, fine for test purposes
            addressId: propertyStringId,
            apartment: getApartmentIdentifier(householdNumericId),
            // Reuse the household id for alarm status, fine for test purposes
            alarmStatusIdsArray: [
              householdStringId + '-ventilation',
              householdStringId + '-leakage',
            ],
            // Property 100 and 200 are the ones that are fake-synchronized from Unik
            // in demo mode, and thus should have external-households
            hasExternalInfo:
              propertyNumericId === 100 || propertyNumericId === 200,
          })
        )

        // Property 100 and 200 are the ones that are fake-synchronized from Unik
        // in demo mode, and thus should have external-households
        if (propertyNumericId === 100 || propertyNumericId === 200) {
          externalHouseholds.push(
            generateExternalInfo({
              // Reuse the household id for external info, fine for test purposes
              id: householdStringId,
              type: 'external-households',
            })
          )
          externalTenancies.push(
            generateExternalInfo({
              // Reuse the household id for external info, fine for test purposes
              id: householdStringId,
              type: 'external-tenancies',
            })
          )
        }

        tenants.push(
          generateTenant({
            // Reuse the household id for tenant id, fine for test purposes
            id: householdStringId,
            name: getName(),
            phone: getPhoneNumber(),
          })
        )

        tenancies.push(
          generateTenancy({
            // Reuse the household id for tenancy id, fine for test purposes
            id: householdStringId,
            // Property 100 and 200 are the ones that are fake-synchronized from Unik
            // in demo mode, and thus should have external-tenancies
            hasExternalInfo:
              propertyNumericId === 100 || propertyNumericId === 200,
          })
        )

        emailSettings.push(
          generateEmailSettings({
            // Reuse the household id for email settings id, fine for test purposes
            id: householdStringId,
          })
        )

        alarmStatuses.push(
          generateAlarmStatus({
            // Reuse the household id for alarm status, fine for test purposes
            id: householdStringId + '-ventilation',
            type: 'ventilation',
            status: getWithProbability({
              ok: 12,
              warn: 1,
              alert: 1,
              unknown: 1,
            }),
          })
        )

        alarmStatuses.push(
          generateAlarmStatus({
            // Reuse the household id for alarm status, fine for test purposes
            id: householdStringId + '-leakage',
            type: 'leakage',
            status: ['301', '302'].includes(householdStringId)
              ? 'alert'
              : ['101', '102'].includes(householdStringId)
              ? 'ok'
              : 'not-available',
          })
        )
      })
    })

  return wrapResponse({
    data: properties,
    included: [
      ...households,
      ...tenants,
      ...tenancies,
      ...emailSettings,
      ...addresses,
      ...alarmStatuses,
      ...externalProperties,
      ...externalHouseholds,
      ...externalTenancies,
    ],
  })
}
