import { wrapResponse } from './utils'

export default function generateSettlementKeys() {
  return wrapResponse({
    data: [
      {
        attributes: {
          name: 'Areal',
          unit: 'm²',
        },
        id: '2b58c597-8536-4de8-9e9f-9fac05ca2db5',
        type: 'settlement-keys',
      },
      {
        attributes: {
          name: 'Haneandele',
          unit: 'tap shares',
        },
        id: 'ad8e9b61-fd26-4400-b93c-5e4252cba113',
        type: 'settlement-keys',
      },
    ],
  })
}
