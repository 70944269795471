export default function usersEndpointConverter({ data }) {
  const users = data.map((u) => ({
    id: u.id,
    name: u.attributes.name,
    email: u.attributes.email,
    activatedAt: u.attributes['activated-at'],
  }))

  return users
}
