import React, { Component } from 'react'
import { Subscribe } from 'bey-fix'
import { Location } from '@reach/router'
import { Formik } from 'formik'
import { datadogLogs } from '@datadog/browser-logs'

import Alert from '../Alert'
import Button from '../Button'
import FormLabel from '../FormLabel'
import Input from '../Input'
import Modal from '../Modal'

import { setUser } from '../state/user'
import accounts, {
  setAccountList,
  setActiveApiKey,
  setShowLoginModal,
  sortAccounts,
} from '../state/accounts'

import { fetchAccountUsers, requestPasswordReset } from '../helpers/api'

import personIcon from '../graphics/person.svg'

export function getDefaultActiveApiKey(accountList) {
  if (!accountList || accountList.length === 0) {
    return null
  }

  const sortedAccountList = sortAccounts(accountList)
  const firstAdminAccount = sortedAccountList.find((x) => x.role === 'admin')

  if (firstAdminAccount) {
    return firstAdminAccount.apiKey
  }
  return sortedAccountList[0].apiKey
}

class Authentication extends Component {
  constructor(props) {
    super(props)
    this.state = {
      submitError: null,
      showPasswordEmailSent: false,
    }
  }

  dismissModal = () => {
    setShowLoginModal(false)
    this.setState({ submitError: null, showPasswordEmailSent: false })
  }

  handleSubmit = async (values, actions) => {
    this.setState({
      submitError: null,
    })

    try {
      const { user, accountList } = await fetchAccountUsers({
        email: values.email,
        password: values.password,
      })
      actions.setSubmitting(false)
      this.props.navigate('/')
      setUser(user)
      setAccountList(accountList)
      setActiveApiKey(getDefaultActiveApiKey(accountList))
      this.dismissModal()
      datadogLogs.logger.info('Authentication.handleSubmit: Logged in')
    } catch (error) {
      setActiveApiKey(null)
      actions.setSubmitting(false)
      this.setState({
        submitError: error.message,
      })
      datadogLogs.logger.error(`Authentication.handleSubmit: ${error.message}`)
    }
  }

  handleForgotPassword = async ({ email, validateForm }) => {
    const errors = await validateForm()
    this.setState({ showPasswordEmailSent: false, submitError: null })
    if (email && !errors.email) {
      try {
        await requestPasswordReset({ email })
        this.setState({ showPasswordEmailSent: true, submitError: null })
      } catch (error) {
        this.setState({
          showPasswordEmailSent: false,
          submitError: error.message,
        })
        datadogLogs.logger.error(
          `Authentication.handleForgotPassword: ${error.message}`
        )
      }
    }
  }

  handleValidation = ({ email, password }) => {
    let errors = {}
    // Required and must be valid
    if (!email || !/^\S+@\S+\.\S+$/.test(email)) {
      errors.email = 'Udfyld en gyldig emailadresse'
    }
    if (!password) {
      errors.password = 'Udfyld venligst dit password'
    } else if (password.length < 8) {
      errors.password = 'Password er mindst 8 tegn'
    }
    return errors
  }

  render() {
    return (
      <Modal
        onClose={this.dismissModal}
        isVisible={this.props.isVisible}
        title="Log ind"
        icon={personIcon}
      >
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={this.handleSubmit}
          validate={this.handleValidation}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
            isSubmitting,
            errors,
            touched,
            validateForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <FormLabel title="E-mail" className="db mt4 mb3">
                <Input
                  type="text"
                  name="email"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  errorMessage={touched.email && errors.email}
                />
              </FormLabel>
              {this.state.showPasswordEmailSent ? (
                <Alert
                  type="info"
                  isDismissable={true}
                  onDismiss={() =>
                    this.setState({ showPasswordEmailSent: false })
                  }
                >
                  Vi har sendt dig en email med et link til at nulstille dit
                  kodeord.
                </Alert>
              ) : (
                <FormLabel title="Kodeord" className="db mb3">
                  <Input
                    type="password"
                    name="password"
                    value={values.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errorMessage={touched.password && errors.password}
                  />
                </FormLabel>
              )}
              {this.state.submitError && (
                <Alert
                  type="error"
                  isDismissable={true}
                  onDismiss={() => this.setState({ submitError: null })}
                >
                  {this.state.submitError}
                </Alert>
              )}
              <div className="mt3 flex flex-row-reverse items-center">
                <Button
                  variant="button"
                  type="submit"
                  loading={isSubmitting}
                  disabled={this.state.showPasswordEmailSent}
                >
                  Log ind
                </Button>
                <Button
                  onClick={this.dismissModal}
                  variant="link"
                  className="ml-auto mr3"
                  type="button"
                >
                  Se en demo
                </Button>
                <Button
                  onClick={() =>
                    this.handleForgotPassword({
                      email: values.email,
                      validateForm,
                    })
                  }
                  title="Få tilsendt link til at nulstille dit kodeord"
                  disabled={this.state.showPasswordEmailSent}
                  type="button"
                  variant="link"
                  slim={true}
                >
                  Glemt kodeord?
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    )
  }
}

export default function SubscribedAuthentication(props) {
  return (
    <Location>
      {({ navigate }) => (
        <Subscribe to={accounts} on={(state) => state.showLoginModal}>
          {(showLoginModal) => (
            <Authentication isVisible={showLoginModal} navigate={navigate} />
          )}
        </Subscribe>
      )}
    </Location>
  )
}
