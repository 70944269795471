import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import get from 'lodash/get'

import STATUS_TEXTS from '../config/statusTexts.json'
import ALLOWED_STATUSES from '../config/allowedStatuses'

const StatusBadge = ({
  status,
  type = 'aggregate',
  size = '16',
  withIcon = false,
  withTitle = true,
  title,
  style,
  className,
}) => {
  if (!status || !ALLOWED_STATUSES.includes(status)) {
    return null
  }
  const file = require(`../graphics/status-${status}${
    withIcon === true ? '-icon' : ''
  }.svg`).default
  return (
    <img
      src={file}
      alt={status}
      width={size}
      height={size}
      className={cx('mw-none', withTitle && 'help', className)}
      style={style}
      title={
        withTitle
          ? title || get(STATUS_TEXTS, [type, status]) || 'Ukendt'
          : null
      }
    />
  )
}

StatusBadge.propTypes = {
  status: PropTypes.oneOf(ALLOWED_STATUSES),
  type: PropTypes.string,
  size: PropTypes.number,
  withIcon: PropTypes.bool,
}

export default StatusBadge
