import DemoStatementZip from '../../../documents/wise-home-demo-regnskab.zip'

export function generateStatementFile({
  approvalDate,
  version = 1,
  id,
  externalResourceId,
  statementId,
}) {
  return {
    attributes: {
      'approval-date': approvalDate,
      version,
    },
    id,
    relationships: {
      'external-resource': {
        data: {
          id: externalResourceId,
          type: 'external-resources',
        },
      },
      statement: {
        data: {
          id: statementId,
          type: 'statements',
        },
      },
    },
    type: 'statement-files',
  }
}

export function generateExternalResource({ id, description }) {
  return {
    attributes: {
      description,
      kind: 'statement_zip',
      'signed-url': DemoStatementZip,
    },
    id,
    type: 'external-resources',
  }
}
