/**
 * httpClient.js contains two axios instances:
 *
 * 1. The real axios instance which will actually send requests to the Legolas
 *    backend,
 *
 * 2. A demo mode axios instance, which has axios-mock-adapter set up to catch
 *    requests and return predefined data
 *
 * This module exports apiProxy() which will determine if we are in demo-mode or
 * not and return the appropriate axios instance.
 */

import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import * as R from 'ramda'
import build from 'redux-object'
import dayjs from 'dayjs'
import random from 'lodash/random'
import merge from 'lodash/merge'
import get from 'lodash/get'
import set from 'lodash/set'
import unset from 'lodash/unset'
import filter from 'lodash/filter'
import times from 'lodash/times'
import normalize from 'json-api-normalizer'

import accounts from '../../state/accounts'
import generateProperties from './generators/properties'
import generateUsers, { generateUser } from './generators/users'
import generatePing from './generators/ping'
import generateDevices from './generators/devices'
import generateVentilationReport from './generators/ventilationReport'
import generateDistributedExpense from './generators/distributedExpense'
import generateUsageReport from './generators/usageReport'
import generateAccountPayments, {
  generateAccountPayment,
} from './generators/accountPayments'
import generateSettlementKeys from './generators/settlementKeys.js'
import { generateFiscalYear } from './generators/fiscalYears'
import {
  generateStatement,
  generateStatementConfig,
} from './generators/statements'
import {
  generateStatementFile,
  generateExternalResource,
} from './generators/statementFiles'
import { generateEmailSettings } from './generators/tenants'
import { wrapResponse } from './generators/utils'
import { getRandom } from '../fake'
import { DISTRIBUTED_EXPENSE_TYPES } from '../../config/distributedExpenseTypes'

const axiosConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 18000,
  headers: {
    'Content-Type': 'application/vnd.api+json',
    Accept: 'application/vnd.api+json',
  },
}

/* 1. Axios instance for making requests to the real API -------------------- */
export const realApi = axios.create(axiosConfig)
export const realApiAsAngel = axios.create(axiosConfig)

// Intercept requests to authenticate with API key, if available
realApi.interceptors.request.use((config) => {
  const accountsState = accounts.get()
  const activeApiKey = accountsState.activeApiKey
  const activeSudoAccount = accountsState.activeSudoAccount || null

  if (activeApiKey) {
    config.auth = { username: activeSudoAccount, password: activeApiKey }
  }

  return config
})

realApiAsAngel.interceptors.request.use((config) => {
  const accountsState = accounts.get()
  const angelApiKey = R.pipe(
    R.propOr([], 'accountList'),
    R.find(R.propEq('role', 'angel')),
    R.prop('apiKey')
  )(accountsState)

  if (angelApiKey) {
    config.auth = { username: null, password: angelApiKey }
  }

  return config
})

/* 2. Axios instance for mocking requests in demo mode ---------------------- */
const mockApi = axios.create(axiosConfig)

// Set up mocking for demo mode
const demo = new MockAdapter(mockApi)

// Cache some data across different demo requests
let cachedProperties = generateProperties({ count: random(4, 8) })
const propertyIds = cachedProperties.data
  .filter((x) => x.type === 'properties')
  .map(({ id }) => id)
const tenancyIds = cachedProperties.included
  .filter((x) => x.type === 'tenancies')
  .map(({ id }) => id)
const UTILITY_TYPES = ['water', 'heat', 'electricity']
const startDate = dayjs().subtract(1, 'year').startOf('year').toDate()
const cachedStatements = propertyIds.map((id) =>
  generateStatement({
    id,
    startDate,
    relationships: {
      property: {
        data: {
          id,
          type: 'properties',
        },
      },
      'fiscal-years': {
        data: UTILITY_TYPES.map((utilityType) => ({
          id: `${utilityType}-${id}`,
          type: 'fiscal-years',
        })),
      },
      // Link to statement files that will be generated on-the-fly
      'statement-files': {
        data: [
          {
            id,
            type: 'statement-files',
          },
        ],
      },
    },
  })
)

let cachedAccountPayments = []
let cachedFiscalYears = []
propertyIds.forEach((propertyId) => {
  UTILITY_TYPES.forEach((utilityType) => {
    const relatedTenancyIds = tenancyIds.filter((tenancyId) => {
      // Property 500 has tenancies 501, 502, 503 etc
      const diff = Number(tenancyId) - Number(propertyId)
      return diff >= 0 && diff < 100
    })
    // Generate account payments for "synced" properties
    if (propertyId === '100' || propertyId === '200') {
      relatedTenancyIds.forEach((tenancyId) => {
        cachedAccountPayments.push(
          ...generateAccountPayments({
            tenancyId,
            utilityTypes: UTILITY_TYPES,
            countPerUtilityType: 1,
          })
        )
      })
    }
    cachedFiscalYears.push(
      generateFiscalYear({
        id: `${utilityType}-${propertyId}`,
        startDate,
        utilityType,
        relationships: {
          statement: {
            data: {
              id: propertyId,
              type: 'statements',
            },
          },
          expenses: {
            data: [],
          },
          'account-payments': {
            data: R.pipe(
              R.chain((tenancyId) =>
                UTILITY_TYPES.map((utilityType) => {
                  // Link to account payments that will be created later
                  return `${tenancyId}-${utilityType}-0`
                })
              ),
              R.map((accountPaymentId) => ({
                id: accountPaymentId,
                type: 'account-payments',
              }))
            )(relatedTenancyIds),
          },
          tenancies: {
            data: relatedTenancyIds.map((tenancyId) => ({
              id: tenancyId,
              type: 'tenancies',
            })),
          },
        },
      })
    )
  })
})
const cachedSettlementKeys = generateSettlementKeys()

let cachedUsers = {}
let normalizedCache = {
  ...normalize(cachedProperties, {
    camelizeKeys: false,
    camelizeTypeValues: false,
  }),
  ...normalize(
    {
      included: [
        ...cachedStatements,
        ...cachedFiscalYears,
        ...cachedAccountPayments,
        ...cachedSettlementKeys.data,
      ],
    },
    { camelizeKeys: false, camelizeTypeValues: false }
  ),
}

const addToNormalizedCache = (responseObj) =>
  merge(
    normalizedCache,
    normalize(responseObj, { camelizeKeys: false, camelizeTypeValues: false })
  )

const removeFromNormalizedCache = (type, id) =>
  unset(normalizedCache, [type, id])

demo
  // loadProperties() ------------------------------------------------------- //
  .onGet(
    '/v6/properties?page[size]=999&include=addresses.households.tenant,addresses.households.tenancies,addresses.households.vacancies,addresses.households.alarm-statuses,addresses.households.external-households,external-properties'
  )
  .reply(() => {
    const properties = build(normalizedCache, 'properties', null)
    const addresses = R.chain(R.prop('addresses'))(properties)
    const households = R.chain(R.prop('households'))(addresses)

    return [
      200,
      wrapResponse({
        data: R.pipe(R.prop('properties'), R.values)(normalizedCache),
        included: [
          // addresses:
          ...R.pipe(
            R.propOr({}, 'addresses'),
            R.pick(R.pluck('id')(addresses)),
            R.values
          )(normalizedCache),
          // addresses.households:
          ...R.pipe(
            R.propOr({}, 'households'),
            R.pick(R.pluck('id')(households)),
            R.values
          )(normalizedCache),
          // addresses.households.tenancies:
          ...R.pipe(
            R.propOr({}, 'tenancies'),
            R.pick(
              R.pipe(R.chain(R.prop('tenancies')), R.pluck('id'))(households)
            ),
            R.values
          )(normalizedCache),
          // addresses.households.vacancies:
          ...R.pipe(
            R.propOr({}, 'vacancies'),
            R.pick(
              R.pipe(R.chain(R.prop('vacancies')), R.pluck('id'))(households)
            ),
            R.values
          )(normalizedCache),
          // addresses.households.tenant:
          ...R.pipe(
            R.propOr({}, 'accounts'),
            R.pick(
              R.pipe(R.chain(R.prop('tenant')), R.pluck('id'))(households)
            ),
            R.values
          )(normalizedCache),
          // addresses.households.alarm-statuses:
          ...R.pipe(
            R.propOr({}, 'alarm-statuses'),
            R.pick(
              R.pipe(
                R.chain(R.prop('alarm-statuses')),
                R.pluck('id')
              )(households)
            ),
            R.values
          )(normalizedCache),
          // addresses.households.external-households:
          ...R.pipe(
            R.propOr({}, 'external-households'),
            R.pick(
              R.pipe(
                R.chain(R.propOr([], 'external-households')),
                R.pluck('id')
              )(households)
            ),
            R.values
          )(normalizedCache),
          // external-properties
          ...R.pipe(
            R.propOr({}, 'external-properties'),
            R.pick(
              R.pipe(
                R.chain(R.propOr([], 'external-properties')),
                R.pluck('id')
              )(properties)
            ),
            R.values
          )(normalizedCache),
        ],
      }),
    ]
  })

  // loadPropertiesAndHouseholds() ------------------------------------------ //
  .onGet(
    '/v6/properties?page[size]=999&include=addresses.households.tenant,addresses.households.alarm-statuses'
  )
  .reply(() => {
    const properties = build(normalizedCache, 'properties', null)
    const addresses = R.chain(R.prop('addresses'))(properties)
    const households = R.chain(R.prop('households'))(addresses)

    return [
      200,
      wrapResponse({
        data: R.pipe(R.prop('properties'), R.values)(normalizedCache),
        included: [
          // addresses:
          ...R.pipe(
            R.propOr({}, 'addresses'),
            R.pick(R.pluck('id')(addresses)),
            R.values
          )(normalizedCache),
          // addresses.households:
          ...R.pipe(
            R.propOr({}, 'households'),
            R.pick(R.pluck('id')(households)),
            R.values
          )(normalizedCache),
          // addresses.households.tenant:
          ...R.pipe(
            R.propOr({}, 'accounts'),
            R.pick(
              R.pipe(R.chain(R.prop('tenant')), R.pluck('id'))(households)
            ),
            R.values
          )(normalizedCache),
          // addresses.households.alarm-statuses:
          ...R.pipe(
            R.propOr({}, 'alarm-statuses'),
            R.pick(
              R.pipe(
                R.chain(R.prop('alarm-statuses')),
                R.pluck('id')
              )(households)
            ),
            R.values
          )(normalizedCache),
        ],
      }),
    ]
  })

  // syncProperty() --------------------------------------------------------- //
  .onPost('/v6/property-syncs')
  .reply((config) => {
    const { data } = JSON.parse(config.data)
    const propertyId = get(data, ['relationships', 'property', 'data', 'id'])
    const externalPropertyId = get(normalizedCache, [
      'properties',
      propertyId,
      'relationships',
      'external-properties',
      'data',
      0,
      'id',
    ])
    addToNormalizedCache({
      data: R.merge(
        get(normalizedCache, ['external-properties', externalPropertyId]),
        {
          attributes: {
            'last-synced-at': new Date().toISOString(),
          },
          type: 'external-properties',
        }
      ),
    })
    // The response data isn't used, so just reply with a 200 status
    return [200]
  })

  // fetchUsers() ----------------------------------------------------------- //
  .onGet(/^\/v6\/users\?filter\[account-id\]=\d+/)
  .reply((config) => {
    const accountId = /filter\[account-id\]=(\d+)/.exec(config.url)[1]
    // Have no users been cached for this account?
    if (!cachedUsers[accountId]) {
      const tenant = R.pipe(
        R.pathOr({}, ['accounts', accountId, 'attributes']),
        R.pick(['name', 'email'])
      )(normalizedCache)

      const generatedUsers = generateUsers({
        count: random(0, 3),
        usersArray: [tenant],
      })

      // Cache the users in order to return the same result the next time
      cachedUsers[accountId] = generatedUsers
    }
    return [200, cachedUsers[accountId]]
  })

  // fetchLoggedInUser() ---------------------------------------------------- //
  .onGet(/^\/v6\/ping/)
  .reply(() => [200, generatePing()])

  // loadDevicesByHousehold ------------------------------------------------- //
  .onGet(
    /^\/v6\/devices\?filter\[household-id\]=\d+&include=room,device-type,last-measurements/
  )
  .reply(() => [200, generateDevices()])

  // loadHousehold() -------------------------------------------------------- //
  .onGet(
    /^\/v6\/households\/\d+\?include=external-households,alarm-statuses,address.property,tenant,vacancies,tenancies.tenant.email-settings/
  )
  .reply((config) => {
    const householdId = /\/households\/(\d+)/.exec(config.url)[1].toString()
    const household = build(normalizedCache, 'households', householdId)
    const tenancies = R.propOr([], 'tenancies')(household)
    const vacancies = R.propOr([], 'vacancies')(household)
    const tenants = R.chain(R.prop('tenant'))(tenancies)
    return [
      200,
      wrapResponse({
        data: get(normalizedCache, ['households', householdId]),
        included: [
          // external-households
          ...R.pipe(
            R.propOr({}, 'external-households'),
            R.pick(
              R.pipe(
                R.propOr([], 'external-households'),
                R.pluck('id')
              )(household)
            ),
            R.values
          )(normalizedCache),
          // alarm-statuses
          ...R.pipe(
            R.propOr({}, 'alarm-statuses'),
            R.pick(R.pluck('id', household['alarm-statuses'])),
            R.values
          )(normalizedCache),
          // address
          get(normalizedCache, [
            'addresses',
            get(household, ['address', 'id']),
          ]),
          // address.property
          get(normalizedCache, [
            'properties',
            get(household, ['address', 'property', 'id']),
          ]),
          // tenant
          get(normalizedCache, ['accounts', get(household, ['tenant', 'id'])]),
          // vacancies
          ...R.pipe(
            R.propOr({}, 'vacancies'),
            R.pick(R.pluck('id')(vacancies)),
            R.values
          )(normalizedCache),
          // tenancies
          ...R.pipe(
            R.propOr({}, 'tenancies'),
            R.pick(R.pluck('id')(tenancies)),
            R.values
          )(normalizedCache),
          // tenancies.tenant
          ...R.pipe(
            R.propOr({}, 'accounts'),
            R.pick(R.pluck('id')(tenants)),
            R.values
          )(normalizedCache),
          // tenancies.tenant.email-settings
          ...R.pipe(
            R.propOr({}, 'account-email-settings'),
            R.pick(R.map(R.path(['email-settings', 'id']))(tenants)),
            R.values
          )(normalizedCache),
        ].filter(Boolean),
      }),
    ]
  })

  // fetchVentilationReport() ----------------------------------------------- //
  .onPost('/v6/reports/ventilation')
  .reply((config) => {
    const { data } = JSON.parse(config.data)
    return [
      200,
      generateVentilationReport({
        fromDate: data.attributes['from-date'],
        toDate: data.attributes['to-date'],
      }),
    ]
  })

  // fetchUsageReport() ----------------------------------------------------- //
  .onPost(
    /^\/v6\/reports\/(water-usage|heat-usage|heat-cost|electricity-usage)$/
  )
  .reply((config) => {
    const { data } = JSON.parse(config.data)
    return [
      200,
      generateUsageReport({
        fromDate: new Date(data.attributes['from-date']),
        toDate: new Date(data.attributes['to-date']),
        type: data.type,
        waterType: data.attributes['water-type'],
      }),
    ]
  })

  // inviteUser() ----------------------------------------------------------- //
  .onPost(/^\/v6\/accounts\/\d+\/invitations/)
  .reply((config) => {
    const accountId = /\/accounts\/(\d+)\/invitations/.exec(config.url)[1]
    const { data } = JSON.parse(config.data)
    const nextUserId = get(cachedUsers, [accountId, 'data', 'length'], 0) + 1
    const newUser = generateUser({
      id: nextUserId,
      name: data.attributes['user-name'],
      email: data.attributes['user-email'],
    })
    // Add the new user to the cache of users for that account
    if (cachedUsers[accountId].data) {
      cachedUsers[accountId].data.push(newUser)
    }
    return [204]
  })

  // createTenant() --------------------------------------------------------- //
  // Echo the data from the patch request back and add id and email-settings.
  .onPost('/v6/accounts?include=email-settings')
  .reply((config) => {
    const fakeGuid = times(24, () => random(0, 9)).join('')
    const { data } = JSON.parse(config.data)
    data.id = fakeGuid
    // Re-use the same id for tenant and email-settings, fine for test purposes
    set(data, ['relationships', 'email-settings'], {
      data: {
        id: fakeGuid,
        type: 'account-email-settings',
      },
    })
    const generatedEmailSettings = generateEmailSettings({ id: fakeGuid })
    // Cache tenant and email-settings for future use
    addToNormalizedCache({ data, included: [generatedEmailSettings] })
    return [
      200,
      wrapResponse({
        data,
        included: [generatedEmailSettings],
      }),
    ]
  })

  // updateTenant() --------------------------------------------------------- //
  // Echo the data from the patch request back
  .onPatch(/^\/v6\/accounts\/\d+/)
  .reply((config) => {
    const { data } = JSON.parse(config.data)
    // Update in the normalized cache
    addToNormalizedCache({
      data: R.merge(get(normalizedCache, ['accounts', data.id]), data),
    })
    return [200, { data }]
  })

  // createTenancy() -------------------------------------------------------- //
  // Echo the data from the patch request back and add id.
  .onPost('/v6/tenancies')
  .reply((config) => {
    const { data } = JSON.parse(config.data)
    const householdId = get(data, 'relationships.household.data.id')
    const fakeGuid = times(24, () => random(0, 9)).join('')
    data.id = fakeGuid
    addToNormalizedCache({ data })
    // Add reference to new tenancy to existing household
    const updatedHousehold = R.pipe(
      R.path(['households', householdId]),
      R.evolve({
        relationships: {
          tenancies: {
            data: R.append({ id: data.id, type: 'tenancies' }),
          },
        },
      })
    )(normalizedCache)
    addToNormalizedCache({ data: updatedHousehold })
    return [200, wrapResponse({ data })]
  })

  // updateTenancy() -------------------------------------------------------- //
  // Echo the data from the patch request back
  .onPatch(/^\/v6\/tenancies\/[a-f0-9-]+/)
  .reply((config) => {
    const { data } = JSON.parse(config.data)
    // Update in the normalized cache
    addToNormalizedCache({
      data: R.merge(get(normalizedCache, ['tenancies', data.id]), data),
    })
    return [200, { data }]
  })

  // deleteTenancy() -------------------------------------------------------- //
  .onDelete(/^\/v6\/tenancies\/.*/)
  .reply((config) => {
    const tenancyId = /\/tenancies\/(.*)$/.exec(config.url)[1]
    // Remove from cache
    removeFromNormalizedCache('tenancies', tenancyId)
    // If tenancy matches current tenant, remove tenant from household
    // The tenancy id matches the household id
    if (get(normalizedCache, ['households', tenancyId])) {
      addToNormalizedCache({
        data: R.assocPath(
          ['relationships', 'tenant', 'data'],
          null
        )(get(normalizedCache, ['households', tenancyId])),
      })
    }
    // The response data isn't used, so just reply with a 204 status
    return [204]
  })

  // createAccountPayment() ------------------------------------------------- //
  // TODO: include fiscal year in generated output
  .onPost('/v6/account-payments?include=fiscal-year')
  .reply((config) => {
    // Echo the request back, adding a generated id
    const { data } = JSON.parse(config.data)
    data.id = `${Date.now()}-${get(data, [
      'relationships',
      'tenancy',
      'data',
      'id',
    ])}-${get(data, ['relationships', 'fiscal-year', 'data', 'id'])}`
    // Add reference to new account payment to existing fiscal year
    const fiscalYearId = R.path(['relationships', 'fiscal-year', 'data', 'id'])(
      data
    )
    const updatedFiscalYear = R.pipe(
      R.path(['fiscal-years', fiscalYearId]),
      R.evolve({
        relationships: {
          'account-payments': {
            data: R.append({ id: data.id, type: 'account-payments' }),
          },
        },
      })
    )(normalizedCache)
    addToNormalizedCache({ data, included: [updatedFiscalYear] })
    return [200, { data }]
  })

  // deleteAccountPayment() ------------------------------------------------- //
  .onDelete(/^\/v6\/account-payments\/\d+/)
  .reply((config) => {
    const accountPaymentId = /\/account-payments\/(.*)$/.exec(config.url)[1]
    // Remove from cache
    removeFromNormalizedCache('account-payments', accountPaymentId)
    // The response data isn't used, so just reply with a 204 status
    return [204]
  })

  // toggleExcludedAccountPayment ------------------------------------------- //
  .onPatch(/^\/v6\/account-payments\/\d+/)
  .reply((config) => {
    const { data } = JSON.parse(config.data)
    const updatedAccountPayment = R.merge(
      get(normalizedCache, ['account-payments', data.id]),
      data
    )
    // Update in the normalized cache
    addToNormalizedCache({
      data: updatedAccountPayment,
    })
    return [200, { data: updatedAccountPayment }]
  })

  //
  //

  // createVacancyAccountPayment() ------------------------------------------------- //
  .onPost('/v6/vacancy-account-payments')
  .reply((config) => {
    // Echo the request back, adding a generated id
    const { data } = JSON.parse(config.data)
    data.id = `${Date.now()}-${get(data, [
      'relationships',
      'vacancy',
      'data',
      'id',
    ])}-${get(data, ['relationships', 'fiscal-year', 'data', 'id'])}`
    // Add reference to new account payment to existing fiscal year
    const fiscalYearId = R.path(['relationships', 'fiscal-year', 'data', 'id'])(
      data
    )
    const updatedFiscalYear = R.pipe(
      R.path(['fiscal-years', fiscalYearId]),
      R.evolve({
        relationships: {
          'vacancy-account-payment': {
            data: R.append({ id: data.id, type: 'vacancy-account-payments' }),
          },
        },
      })
    )(normalizedCache)
    addToNormalizedCache({ data, included: [updatedFiscalYear] })
    return [200, { data }]
  })

  // deleteVacancyAccountPayment() ------------------------------------------------- //
  .onDelete(/^\/v6\/vacancy-account-payments\/\d+/)
  .reply((config) => {
    const vacancyAccountPaymentId = /\/vacancy-account-payments\/(.*)$/.exec(
      config.url
    )[1]
    // Remove from cache
    removeFromNormalizedCache(
      'vacancy-account-payments',
      vacancyAccountPaymentId
    )
    // The response data isn't used, so just reply with a 204 status
    return [204]
  })

  //
  //

  // toggleExcludedVacancyAccountPayment ------------------------------------------- //
  .onPatch(/^\/v6\/vacancy-account-payments\/\d+/)
  .reply((config) => {
    const { data } = JSON.parse(config.data)
    const updatedVacancyAccountPayment = R.merge(
      get(normalizedCache, ['vacancy-account-payments', data.id]),
      data
    )
    // Update in the normalized cache
    addToNormalizedCache({
      data: updatedVacancyAccountPayment,
    })
    return [200, { data: updatedVacancyAccountPayment }]
  })

  // loadStatements() ------------------------------------------------------- //
  .onGet(
    /\/v6\/statements\?page\[size\]=999&include=property.external-properties,property.addresses.households,fiscal-years.expenses,fiscal-years.account-payments,fiscal-years.tenancies/
  )
  .reply(() => {
    const statements = R.values(normalizedCache['statements'] || {})
    const fiscalYears = R.values(normalizedCache['fiscal-years'] || {})
    const properties = R.values(normalizedCache['properties'] || {})
    const externalProperties = R.values(
      normalizedCache['external-properties'] || {}
    )
    const addresses = R.values(normalizedCache['addresses'] || {})
    const households = R.values(normalizedCache['households'] || {})
    const tenancies = R.values(normalizedCache['tenancies'] || {})
    const expenses = R.values(normalizedCache['expenses'] || {})

    let accountPayments = R.values(normalizedCache['account-payments'] || {})

    return [
      200,
      wrapResponse({
        data: statements,
        included: [
          ...fiscalYears,
          ...properties,
          ...externalProperties,
          ...addresses,
          ...households,
          ...tenancies,
          ...expenses,
          ...accountPayments,
        ],
      }),
    ]
  })

  // loadStatementWithExpenses() -------------------------------------------- //
  .onGet(
    /\/v6\/statements\/[a-zA-Z0-9-]+\?include=fiscal-years.expenses,fiscal-years.distributed-expenses,fiscal-years.fuel-check-points,property$/
  )
  .reply((config) => {
    const statementId = /\/statements\/([a-zA-Z0-9-]+)/
      .exec(config.url)[1]
      .toString()
    const statement = get(normalizedCache, ['statements', statementId])
    const propertyId = statementId
    const property = get(normalizedCache, ['properties', propertyId])
    const fiscalYears = filter(
      normalizedCache['fiscal-years'],
      (x) => x.relationships.statement.data.id === statementId
    )
    const fiscalYearIds = fiscalYears.map(({ id }) => id)
    const expenses = filter(normalizedCache['expenses'], (x) =>
      fiscalYearIds.includes(x.relationships['fiscal-year'].data.id)
    )
    const distributedExpenses = filter(
      normalizedCache['distributed-expenses'],
      (x) => fiscalYearIds.includes(x.relationships['fiscal-year'].data.id)
    )

    return [
      200,
      wrapResponse({
        data: statement,
        included: [
          property,
          ...fiscalYears,
          ...expenses,
          ...distributedExpenses,
        ],
      }),
    ]
  })

  // loadStatementWithConfig() ---------------------------------------------- //
  .onGet(/\/v6\/statements\/[a-zA-Z0-9-]+\?include=property,statement-config/)
  .reply((config) => {
    const statementId = /\/statements\/([a-zA-Z0-9-]+)/
      .exec(config.url)[1]
      .toString()
    let statement = get(normalizedCache, ['statements', statementId])
    const propertyId = statementId
    const property = get(normalizedCache, ['properties', propertyId])
    const statementConfigId = propertyId
    let statementConfig = get(normalizedCache, [
      'statement-configs',
      statementConfigId,
    ])

    if (!statementConfig) {
      statementConfig = generateStatementConfig({ id: statementConfigId })
      // Add reference to new statement config to existing statement
      statement = R.pipe(
        R.path(['statements', statementId]),
        R.evolve({
          relationships: {
            'statement-configs': {
              data: R.append({
                id: statementConfigId,
                type: 'statement-configs',
              }),
            },
          },
        })
      )(normalizedCache)
      addToNormalizedCache({
        data: statement,
        included: [statementConfig],
      })
    }

    return [
      200,
      wrapResponse({
        data: statement,
        included: [property, statementConfig],
      }),
    ]
  })

  // updateStatementConfig() ------------------------------------------------ //
  // Echo the data from the patch request back
  .onPatch(/^\/v6\/statement-configs\/\d+/)
  .reply((config) => {
    const { data } = JSON.parse(config.data)
    // Update in the normalized cache
    addToNormalizedCache({
      data: R.merge(get(normalizedCache, ['statement-configs', data.id]), data),
    })
    return [200, { data }]
  })

  // loadStatementWithExpensesAndAccountPayments() -------------------------- //
  .onGet(
    /\/v6\/statements\/[a-zA-Z0-9-]+\?include=fiscal-years.expenses,fiscal-years.account-payments,fiscal-years.tenancies.tenant,fiscal-years.vacancies.household,fiscal-years.vacancies.vacancy-info.vacancy-account-payments,property.addresses.households,property.external-properties,statement-files/
  )
  .reply((config) => {
    console.log('DEMO API with expense and account payments')
    const statementId = /\/statements\/([a-zA-Z0-9-]+)/
      .exec(config.url)[1]
      .toString()
    const statement = get(normalizedCache, ['statements', statementId])
    // Statements have been generated with an id matching a property
    const propertyId = statementId
    const property = get(normalizedCache, ['properties', propertyId])
    const fiscalYears = filter(
      normalizedCache['fiscal-years'],
      (x) => x.relationships.statement.data.id === statementId
    )
    const fiscalYearIds = fiscalYears.map(({ id }) => id)
    const expenses = filter(normalizedCache['expenses'], (x) =>
      fiscalYearIds.includes(x.relationships['fiscal-year'].data.id)
    )
    // Take advantage of the fact that all demo entities related to a property
    // share that property's base id number, e.g. property "100" has households
    // "101", "102", "103" etc.
    function filterById(_val, key) {
      const diff = Number(key) - Number(propertyId)
      return diff >= 0 && diff < 100
    }

    const addresses = filter(normalizedCache.addresses, filterById)
    const households = filter(normalizedCache.households, filterById)
    const tenancies = filter(normalizedCache.tenancies, filterById)
    const tenants = filter(normalizedCache.accounts, filterById)
    const externalProperties = filter(
      normalizedCache['external-properties'],
      filterById
    )
    // No vacancies in demo mode currently
    const vacancies = []
    const vacancyInfos = []
    const vacancyAccountPayments = []

    let accountPayments = filter(normalizedCache['account-payments'], (x) =>
      fiscalYearIds.includes(x.relationships['fiscal-year'].data.id)
    )

    const statementFile = generateStatementFile({
      approvalDate: dayjs(statement?.attributes?.['end-date'])
        .add(3, 'weeks')
        .format('YYYY-MM-DD'),
      version: 1,
      id: statementId,
      statementId: statementId,
    })

    return [
      200,
      wrapResponse({
        data: statement,
        included: [
          property,
          ...fiscalYears,
          ...addresses,
          ...households,
          ...tenancies,
          ...tenants,
          ...expenses,
          ...accountPayments,
          ...externalProperties,
          ...vacancies,
          ...vacancyInfos,
          ...vacancyAccountPayments,
          statementFile,
        ],
      }),
    ]
  })

  // loadStatementWithFiles ------------------------------------------------ //
  .onGet(
    /\/v6\/statements\/[a-zA-Z0-9-]+\?include=statement-files.external-resource/
  )
  .reply((config) => {
    const statementId = /\/statements\/([a-zA-Z0-9-]+)/
      .exec(config.url)[1]
      .toString()
    const statement = get(normalizedCache, ['statements', statementId])

    const statementFile = generateStatementFile({
      approvalDate: dayjs(statement?.attributes?.['end-date'])
        .add(3, 'weeks')
        .format('YYYY-MM-DD'),
      version: 1,
      id: statementId,
      statementId: statementId,
      externalResourceId: statementId,
    })

    const externalResource = generateExternalResource({ id: statementId })

    return [
      200,
      wrapResponse({
        data: statement,
        included: [statementFile, externalResource],
      }),
    ]
  })

  // loadPriorStatements & ------------------------------------------------- //
  // loadPriorStatementsWithAccountPayments -------------------------------- //
  .onGet(
    /^\/v6\/statements\?filter\[property-id\]=\d+&filter\[end-date-from\]=\d{4}-\d{2}-\d{2}&filter\[end-date-to\]=\d{4}-\d{2}-\d{2}&include=.*/
  )
  .reply((config) => {
    const propertyId = (/\[property-id\]=(\d+)/.exec(config.url) ?? [])[1]
    const endDate = (/\[end-date-from\]=(\d{4}-\d{2}-\d{2})/.exec(config.url) ??
      [])[1]
    const includes = (/include=(.+)$/.exec(config.url) ?? [])[1]
    const isDistributedExpenseRequest =
      includes === 'fiscal-years.distributed-expenses'

    const priorStatementStartDate = dayjs(endDate)
      .subtract(1, 'year')
      .add(1, 'day')
      .format('YYYY-MM-DD')

    const priorStatementId = `${propertyId}-prior`

    function getFiscalYearId(utilityType) {
      return `${utilityType}-${propertyId}-prior`
    }

    const fiscalYearIds = UTILITY_TYPES.map(getFiscalYearId)

    const distributedExpenses = fiscalYearIds.map((fiscalYearId) =>
      generateDistributedExpense({
        id: `${fiscalYearId}-distributed-expense`,
        expenseType: getRandom(R.keys(DISTRIBUTED_EXPENSE_TYPES)),
        relationships: {
          'fiscal-year': { data: { id: fiscalYearId, type: 'fiscal-years' } },
          'settlement-key': {
            data: {
              id: R.values(normalizedCache['settlement-keys'] ?? {}).find(
                (x) => x.attributes?.name === 'Areal'
              )?.id,
              type: 'settlement-keys',
            },
          },
        },
      })
    )

    const statement = generateStatement({
      id: priorStatementId,
      startDate: priorStatementStartDate,
      relationships: {
        property: {
          data: {
            id: propertyId,
            type: 'properties',
          },
        },
        'fiscal-years': {
          data: fiscalYearIds.map((fiscalYearId) => ({
            id: fiscalYearId,
            type: 'fiscal-years',
          })),
        },
      },
    })

    const priorStatementTenancies = R.pipe(
      R.propOr({}, 'tenancies'),
      R.values,
      R.filter(({ id }) => {
        // Take advantage of the fact that all demo entities related to a property
        // share that property's base id number, e.g. property "100" has households
        // "101", "102", "103" etc.
        const diff = Number(id) - Number(propertyId)
        return diff >= 0 && diff < 100
      }),
      R.filter(
        R.allPass([
          R.propSatisfies(
            R.either(R.isNil, R.gte(statement?.attributes?.['start-date'])),
            'move-in-date'
          ),
          R.propSatisfies(
            R.either(R.isNil, R.lte(statement?.attributes?.['end-date'])),
            'move-out-date'
          ),
        ])
      )
    )(normalizedCache)

    const accountPayments = priorStatementTenancies.reduce((acc, { id }) => {
      UTILITY_TYPES.forEach((utilityType) => {
        acc.push(
          generateAccountPayment({
            id: `${priorStatementId}-${utilityType}-${id}`,
            utilityType,
            totalAmount: random(2, 5) * 1000 * 100,
            tenancyId: id,
            fiscalYearId: getFiscalYearId(utilityType),
          })
        )
      })
      return acc
    }, [])

    const fiscalYears = fiscalYearIds.map((fiscalYearId, i) =>
      generateFiscalYear({
        utilityType: UTILITY_TYPES[i],
        id: fiscalYearId,
        startDate: priorStatementStartDate,
        relationships: {
          statement: {
            data: {
              id: priorStatementId,
              type: 'statements',
            },
          },
          'distributed-expenses': {
            data: [
              {
                id: `${fiscalYearId}-distributed-expense`,
                type: 'distributed-expenses',
              },
            ],
          },
          'account-payments': {
            data: [
              accountPayments.map(({ id }) => ({
                id,
                type: 'account-payments',
              })),
            ],
          },
        },
      })
    )

    return [
      200,
      wrapResponse({
        data: statement,
        included: isDistributedExpenseRequest
          ? [...fiscalYears, ...distributedExpenses]
          : [...fiscalYears, ...priorStatementTenancies, ...accountPayments],
      }),
    ]
  })

  // lockStatement() ------------------------------------------------------- //
  .onPatch(/^\/v6\/statements\/\d+/)
  .reply((config) => {
    const { data } = JSON.parse(config.data)
    data.attributes['locked-at'] = dayjs().toISOString()
    addToNormalizedCache({ data })
    return [200, wrapResponse({ data })]
  })

  // createExpense() ------------------------------------------------------- //
  .onPost('/v6/expenses')
  .reply((config) => {
    // Echo the request back, adding a generated id, and cache it
    const { data } = JSON.parse(config.data)
    const fakeGuid = times(24, () => random(0, 9)).join('')
    data.id = fakeGuid
    const fiscalYearId = R.path(['relationships', 'fiscal-year', 'data', 'id'])(
      data
    )
    // Add reference to new expense to existing fiscal year
    const updatedFiscalYear = R.pipe(
      R.path(['fiscal-years', fiscalYearId]),
      R.evolve({
        relationships: {
          expenses: {
            data: R.append({ id: data.id, type: 'expenses' }),
          },
        },
      })
    )(normalizedCache)
    addToNormalizedCache({ data, included: [updatedFiscalYear] })
    return [200, { data }]
  })

  // updateExpense ---------------------------------------------------------- //
  .onPatch(/^\/v6\/expenses\/[a-zA-Z0-9-]+/)
  .reply((config) => {
    const { data } = JSON.parse(config.data)
    // Update in the normalized cache
    addToNormalizedCache({
      data: R.merge(get(normalizedCache, ['expenses', data.id]), data),
    })
    return [200, { data }]
  })

  // deleteExpense() ------------------------------------------------------- //
  .onDelete(/^\/v6\/expenses\/[a-zA-Z0-9-]+/)
  .reply((config) => {
    const expenseId = /\/expenses\/([a-zA-Z0-9-]+)/.exec(config.url)[1]
    // Remove from cache
    removeFromNormalizedCache('expenses', expenseId)
    // The response data isn't used, so just reply with a 204 status
    return [204]
  })

  // createDistributedExpense() --------------------------------------------- //
  .onPost('/v6/distributed-expenses')
  .reply((config) => {
    // Echo the request back, adding a generated id, and cache it
    const { data } = JSON.parse(config.data)
    const fakeGuid = times(24, () => random(0, 9)).join('')
    data.id = fakeGuid
    const fiscalYearId = R.path(['relationships', 'fiscal-year', 'data', 'id'])(
      data
    )
    // Add reference to new expense to existing fiscal year
    const updatedFiscalYear = R.pipe(
      R.path(['fiscal-years', fiscalYearId]),
      R.evolve({
        relationships: {
          'distributed-expenses': {
            data: R.append({ id: data.id, type: 'distributed-expenses' }),
          },
        },
      })
    )(normalizedCache)
    addToNormalizedCache({ data, included: [updatedFiscalYear] })
    return [200, { data }]
  })

  // updateDistributedExpense ----------------------------------------------- //
  .onPatch(/^\/v6\/distributed-expenses\/[a-zA-Z0-9-]+/)
  .reply((config) => {
    const { data } = JSON.parse(config.data)
    // Update in the normalized cache
    addToNormalizedCache({
      data: R.merge(
        get(normalizedCache, ['distributed-expenses', data.id]),
        data
      ),
    })
    return [200, { data }]
  })

  // deleteDistributedExpense() --------------------------------------------- //
  .onDelete(/^\/v6\/distributed-expenses\/[a-zA-Z0-9-]+/)
  .reply((config) => {
    const distributedExpenseId = /\/distributed-expenses\/([a-zA-Z0-9-]+)/.exec(
      config.url
    )[1]
    // Remove from cache
    removeFromNormalizedCache('distributed-expenses', distributedExpenseId)
    // The response data isn't used, so just reply with a 204 status
    return [204]
  })

  // loadProperty() --------------------------------------------------------- //
  .onGet(
    /\/v6\/properties\/\d+\?include=external-properties,addresses.households.tenant,addresses.households.tenancies,addresses.households.vacancies,addresses.households.external-households,addresses.households.alarm-statuses/
  )
  .reply((config) => {
    const propertyId = /\/properties\/(\d+)/.exec(config.url)[1].toString()

    // Take advantage of the fact that all demo entities related to a property
    // share that property's base id number, e.g. property "100" has households
    // "101", "102", "103" etc.
    function filterById(_val, key) {
      const diff =
        Number(key.replace(/-(ventilation|leakage)$/, '')) - Number(propertyId)
      return diff >= 0 && diff < 100
    }

    const property = get(normalizedCache, ['properties', propertyId])
    const addresses = filter(normalizedCache.addresses, filterById)
    const households = filter(normalizedCache.households, filterById)
    const tenancies = filter(normalizedCache.tenancies, filterById)
    const vacancies = filter(normalizedCache.vacancies, filterById)
    const tenants = filter(normalizedCache.accounts, filterById)
    const externalProperties = filter(
      normalizedCache['external-properties'],
      filterById
    )
    const externalHouseholds = filter(
      normalizedCache['external-households'],
      filterById
    )
    const alarmStatuses = filter(normalizedCache['alarm-statuses'], filterById)

    return [
      200,
      wrapResponse({
        data: property,
        included: [
          ...addresses,
          ...households,
          ...tenancies,
          ...vacancies,
          ...tenants,
          ...externalProperties,
          ...externalHouseholds,
          ...alarmStatuses,
        ],
      }),
    ]
  })

  // loadProperty() --------------------------------------------------------- //
  .onGet(
    /\/v6\/properties\/\d+\?include=external-properties,addresses.households.tenant,addresses.households.tenancies,addresses.households.settlement-values,addresses.households.vacancies,addresses.households.external-households,addresses.households.alarm-statuses,statements.fiscal-years.water-usage-meter-comparison/
  )
  .reply((config) => {
    const propertyId = /\/properties\/(\d+)/.exec(config.url)[1].toString()

    // Take advantage of the fact that all demo entities related to a property
    // share that property's base id number, e.g. property "100" has households
    // "101", "102", "103" etc.
    function filterById(_val, key) {
      const diff =
        Number(key.replace(/-(ventilation|leakage)$/, '')) - Number(propertyId)
      return diff >= 0 && diff < 100
    }

    const property = get(normalizedCache, ['properties', propertyId])
    const addresses = filter(normalizedCache.addresses, filterById)
    const households = filter(normalizedCache.households, filterById)
    const tenancies = filter(normalizedCache.tenancies, filterById)
    const vacancies = filter(normalizedCache.vacancies, filterById)
    const tenants = filter(normalizedCache.accounts, filterById)
    const externalProperties = filter(
      normalizedCache['external-properties'],
      filterById
    )
    const externalHouseholds = filter(
      normalizedCache['external-households'],
      filterById
    )
    const alarmStatuses = filter(normalizedCache['alarm-statuses'], filterById)

    return [
      200,
      wrapResponse({
        data: property,
        included: [
          ...addresses,
          ...households,
          ...tenancies,
          ...vacancies,
          ...tenants,
          ...externalProperties,
          ...externalHouseholds,
          ...alarmStatuses,
        ],
      }),
    ]
  })

  // patchEmailSettings() -------------------------------------------------- //
  .onPatch(/^\/v6\/accounts\/\d+\/email-settings/)
  .reply((config) => {
    const { data } = JSON.parse(config.data)
    // Update in cache
    addToNormalizedCache({ data })
    // Echo the data from the patch request back
    return [
      200,
      wrapResponse({
        data,
      }),
    ]
  })

  // loadSettlementKeysForProperty ------------------------------------------ //
  .onGet(/^\/v6\/settlement-keys\?filter\[for-property-id\]=\d+/)
  .reply(() => {
    const settlementKeys = R.values(normalizedCache['settlement-keys'] || {})
    return [
      200,
      wrapResponse({
        data: settlementKeys,
      }),
    ]
  })

  .onAny()
  .passThrough()

/* Proxy to determine which axios instance to use --------------------------- */
export function apiProxy() {
  // If an API key is available in local state, use the real API, otherwise fall
  // back to demo mode where requests are mocked
  if (accounts.get().activeApiKey) {
    return realApi
  } else {
    return mockApi
  }
}
