import React from 'react'
import PropTypes from 'prop-types'
import { AnimatePresence, motion } from 'framer-motion'

export default function DemoBanner({ isVisible = false }) {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          key="demo-banner"
          className="pv2 ph3 bg-yellow bt f7 tc lh-title fixed left-0 bottom-0 right-0 shadow-3 z-999"
          style={{
            borderColor: '#E6D44F',
          }}
          initial={{
            y: 50,
          }}
          animate={{
            y: 0,
          }}
          exit={{
            y: 50,
            transition: {
              duration: 2.2,
            },
          }}
        >
          <strong>
            Du prøver lige nu en demo af Wise Home til administratorer.
          </strong>{' '}
          Er du nysgerrig efter mere information, så{' '}
          <a className="link orange" href="https://wisehome.dk/kort-fortalt/">
            tag fat i os med det samme
          </a>
          .
        </motion.div>
      )}
    </AnimatePresence>
  )
}

DemoBanner.propTypes = {
  isVisible: PropTypes.bool,
}
