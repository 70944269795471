import React from 'react'
import * as R from 'ramda'
import { Link, Location } from '@reach/router'
import cx from 'classnames'

import AccountSelector from '../AccountSelector'

import logo from '../graphics/logo.svg'

function Header({ pathname }) {
  const accountPrefix = R.pipe(R.match(/konto\/(\d+|demo)/), R.head)(pathname)

  return (
    <header className="bg-light-gray bb b--light-silver ph4 pv3" role="banner">
      <div className="flex flex-row items-center f6 ph3 pv1">
        <Link to={accountPrefix || '/'}>
          <img
            src={logo}
            alt="Wise Home logo"
            width="106"
            height="22"
            className="br b--silver pb3 pt2 pr4 mr3"
          />
        </Link>
        {accountPrefix && (
          <nav className="no-print">
            <ul className="mv0 pl0 list flex flex-row items-center">
              <MenuLink to={accountPrefix + '/ejendomme'} className="ph3">
                Ejendomme
              </MenuLink>
              <MenuLink to={accountPrefix + '/regnskaber'} className="ph3">
                Regnskaber
              </MenuLink>
              <li>
                <a
                  className="navy no-underline hover-orange ph3"
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://wisehome.dk/support/"
                >
                  Support
                </a>
              </li>
            </ul>
          </nav>
        )}
        <nav className="ml-auto">
          <ul className="mv0 pl0 list flex items-center">
            {accountPrefix && (
              <MenuLink
                to={accountPrefix + '/indstillinger'}
                className="ph3 no-print"
              >
                Indstillinger
              </MenuLink>
            )}
            <li>
              <AccountSelector />
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default function SubscribedHeader(props) {
  return (
    <Location>
      {({ location: { pathname } }) => (
        <Header pathname={pathname} {...props} />
      )}
    </Location>
  )
}

function MenuLink({ className, to, children }) {
  return (
    <li>
      <Link
        to={to}
        getProps={({ isPartiallyCurrent }) => {
          const classes = cx('navy no-underline hover-orange', className)
          return {
            className: isPartiallyCurrent ? cx(classes, 'fw7') : classes,
          }
        }}
      >
        {children}
      </Link>
    </li>
  )
}
