import dayjs from 'dayjs'

export function generateFiscalYear({
  utilityType,
  startDate,
  id,
  relationships,
}) {
  return {
    attributes: {
      'start-date': dayjs(startDate).format('YYYY-MM-DD'),
      'end-date': dayjs(startDate)
        .add(1, 'year')
        .subtract(1, 'day')
        .format('YYYY-MM-DD'),
      'utility-type': utilityType,
    },
    id,
    relationships,
    type: 'fiscal-years',
  }
}
