import random from 'lodash/random'

export default function generateDistributedExpense({
  title,
  expenseType = 'administration',
  id,
  relationships,
}) {
  return {
    attributes: {
      amount: `${random(1, 6) * 1000} DKK`,
      'business-only': false,
      'expense-type': expenseType,
      title: title ?? null,
    },
    id,
    relationships: relationships ?? {},
    type: 'distributed-expenses',
  }
}
