import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import GoogleAnalytics from 'react-ga'
import { Transition } from 'react-transition-group'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import '../styles/transitions.css'

import Box from '../Box'

export default function Modal({ title, icon, onClose, isVisible, children }) {
  useEffect(() => {
    if (isVisible) {
      GoogleAnalytics.modalview(
        (title || 'modal').toLowerCase().replace(/ +/g, '-')
      )
    }
  }, [isVisible, title])

  return (
    <Transition
      in={isVisible}
      timeout={500}
      mountOnEnter={true}
      unmountOnExit={true}
    >
      {(transitionState) => {
        return (
          <DialogOverlay
            style={{
              backgroundColor: 'rgba(3, 49, 73, 0.8)',
              transitionDuration: '500ms',
            }}
            className={cx(
              'fixed top-0 right-0 bottom-0 left-0 flex justify-center overflow-auto',
              'fade',
              `fade-${transitionState}`
            )}
            onDismiss={onClose}
          >
            <DialogContent
              aria-label={title}
              style={{
                marginTop: '20vh',
                transitionDuration: '500ms',
              }}
              className={cx(
                'w-40 measure-wide outline-0',
                'slide',
                `slide-${transitionState}`
              )}
            >
              <Box title={title} icon={icon}>
                {children}
              </Box>
            </DialogContent>
          </DialogOverlay>
        )
      }}
    </Transition>
  )
}

Modal.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  onClose: PropTypes.func,
  isVisible: PropTypes.bool,
  children: PropTypes.node,
}
