import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

import StatusBadge from '../StatusBadge'

import ALLOWED_STATUSES from '../config/allowedStatuses'

const Box = ({ className, style, icon, title, status, children, ...rest }) => (
  <div
    className={cx(
      'bg-white ba b--light-silver br2 pa4 pa3-print shadow-4',
      className
    )}
    style={style}
    {...rest}
  >
    {title && (
      <div className="flex justify-center items-center">
        {icon && (
          <img src={icon} height={30} width={30} alt="" className="mr3" />
        )}
        <h2 className="f4 fw9 mv0 navy">{title}</h2>
        <AnimatePresence initial={false}>
          {status && (
            <motion.div
              key={status}
              initial={{
                opacity: 0.5,
                scale: 0.5,
              }}
              animate={{
                opacity: 1,
                scale: 1,
              }}
              exit={{
                opacity: 0,
                scale: 0,
              }}
              className="ml3"
            >
              <StatusBadge
                status={status}
                withIcon={true}
                withTitle={false}
                size={22}
                className="db"
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    )}
    {children}
  </div>
)

Box.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  icon: PropTypes.string,
  title: PropTypes.string,
  status: PropTypes.oneOf(ALLOWED_STATUSES),
  children: PropTypes.node,
}

export default Box
