import React from 'react'
import { Location } from '@reach/router'
import { Subscribe } from 'bey-fix'
import cx from 'classnames'

import DemoBanner from '../DemoBanner'

import accounts from '../state/accounts'
import { getVersionString } from '../helpers/version'

export function Footer({ showDemoBanner = false }) {
  return (
    <footer
      className={cx('mh4 ph3 pt3 f7 tr', showDemoBanner ? 'pb5' : 'pb3')}
      role="contentinfo"
    >
      {[
        {
          href: 'https://wisehome.dk',
          title: 'Wise Home',
        },
        {
          href: 'mailto:info@wisehome.dk',
          title: 'info@wisehome.dk',
        },
        {
          href: 'tel:+4520693501',
          title: '+45 20 69 35 01',
        },
        {
          href: 'https://wisehome.dk/databehandleraftale',
          title: 'Databehandleraftale',
        },
      ].map(({ href, title }) => (
        <a
          key={href}
          href={href}
          target={/^(mailto:|tel:)/.test(href) ? null : '_blank'}
          rel="noopener noreferrer"
          className="link orange mr3"
        >
          {title}
        </a>
      ))}
      <span className="silver">{getVersionString()}</span>
      <DemoBanner isVisible={showDemoBanner} />
    </footer>
  )
}

export default function SubscribedFooter(props) {
  return (
    <Location>
      {({ location }) => (
        <Subscribe to={accounts}>
          {(accounts) => {
            const isResetPasswordPage = /^\/nulstil-kodeord\//.test(
              location.pathname
            )
            const isAccountActivationPage = /^\/aktivering/.test(
              location.pathname
            )
            return (
              <Footer
                {...props}
                showDemoBanner={
                  !accounts.activeApiKey &&
                  !accounts.showLoginModal &&
                  !isResetPasswordPage &&
                  !isAccountActivationPage
                }
              />
            )
          }}
        </Subscribe>
      )}
    </Location>
  )
}
