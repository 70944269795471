import range from 'lodash/range'
import random from 'lodash/random'

import { VAT_RATE, CURRENCY_CODE } from '../../../config/financial'

function calculateVatAmount(totalAmount) {
  return (totalAmount / (1 + VAT_RATE) - totalAmount) * -1
}

export function generateAccountPayment({
  id,
  utilityType,
  totalAmount,
  accountingDate,
  tenancyId,
  fiscalYearId,
}) {
  return {
    attributes: {
      currency: CURRENCY_CODE,
      'accounting-date': accountingDate,
      'total-amount': totalAmount,
      'utility-type': utilityType,
      'vat-amount': calculateVatAmount(totalAmount),
    },
    id,
    type: 'account-payments',
    relationships: {
      tenancy: {
        data: {
          id: tenancyId,
          type: 'tenancies',
        },
      },
      'fiscal-year': {
        data: {
          id: fiscalYearId,
          type: 'fiscal-years',
        },
      },
    },
  }
}

export default function generateAccountPayments({
  countPerUtilityType = 3,
  tenancyId,
  utilityTypes = ['water', 'heat', 'electricity'],
}) {
  let payments = []
  // Exploit the numeric relationship in demo data — a tenancy with e.g. id
  // "403" will belong to property "400" which has a statement "400" with fiscal
  // years "water-400", "heat-400" and "electricity-400"
  const statementId = (Number(tenancyId) - (Number(tenancyId) % 100)).toString()
  utilityTypes.forEach((utilityType) => {
    const totalAmount = random(2, 5) * 1000 * 100
    range(countPerUtilityType).forEach((step) => {
      payments.push(
        generateAccountPayment({
          id: `${tenancyId}-${utilityType}-${step}`,
          accountingDate: null,
          totalAmount,
          utilityType,
          tenancyId,
          fiscalYearId: `${utilityType}-${statementId}`,
        })
      )
    })
  })
  return payments
}
