import React, { Component, Suspense } from 'react'
import { Router, Redirect } from '@reach/router'
import { datadogLogs } from '@datadog/browser-logs'

import Authentication from './Authentication'
import Header from './Header'
import Footer from './Footer'
import Spinner from './Spinner'
import TrackPageViews from './TrackPageViews'
import NotFound from './NotFound'

import { fetchLoggedInUser } from './helpers/api'

import {
  setActiveApiKey,
  setAccountList,
  setShowLoginModal,
} from './state/accounts'
import { setUser } from './state/user'

// Lazy loaded components
const AccountActivation = React.lazy(() =>
  import(/* webpackChunkName: "AccountActivation" */ './AccountActivation')
)
const AccountEnforcer = React.lazy(() =>
  import(/* webpackChunkName: "AccountEnforcer" */ './AccountEnforcer')
)
const AccountList = React.lazy(() =>
  import(/* webpackChunkName: "AccountList" */ './AccountList')
)
const AccountPayments = React.lazy(() =>
  import(/* webpackChunkName: "AccountPayments" */ './AccountPayments')
)
const Billing = React.lazy(() =>
  import(/* webpackChunkName: "Billing" */ './Billing')
)
const BillingReview = React.lazy(() =>
  import(/* webpackChunkName: "BillingReview" */ './BillingReview')
)
const Dashboard = React.lazy(() =>
  import(/* webpackChunkName: "Dashboard" */ './Dashboard')
)
const Expenses = React.lazy(() =>
  import(/* webpackChunkName: "Expenses" */ './Expenses')
)
const Household = React.lazy(() =>
  import(/* webpackChunkName: "Household" */ './Household')
)
const Properties = React.lazy(() =>
  import(/* webpackChunkName: "Properties" */ './Properties')
)
const Property = React.lazy(() =>
  import(/* webpackChunkName: "Property" */ './Property')
)
const ResetPassword = React.lazy(() =>
  import(/* webpackChunkName: "ResetPassword" */ './ResetPassword')
)
const StatementMessages = React.lazy(() =>
  import(/* webpackChunkName: "StatementMessages" */ './StatementMessages')
)
const UserSettings = React.lazy(() =>
  import(/* webpackChunkName: "UserSettings" */ './UserSettings')
)

/* Main component ----------------------------------------------------------- */
class App extends Component {
  async componentDidMount() {
    try {
      // Hydrate the global accounts state from localStorage
      const activeApiKey = localStorage.getItem('activeApiKey')
      const accountList = JSON.parse(localStorage.getItem('accountList'))
      if (activeApiKey && accountList && accountList.length > 0) {
        setActiveApiKey(activeApiKey)
        setAccountList(accountList)
        setShowLoginModal(false)
        datadogLogs.logger.info(
          'App.componentDidMount: Restored session from localStorage'
        )
      } else {
        datadogLogs.logger.info(
          'App.componentDidMount: Initiated new demo session'
        )
      }
      // Load required data
      const user = await fetchLoggedInUser()
      setUser(user)
    } catch (error) {
      datadogLogs.logger.error(`App.componentDidMount: ${error.message}`)
    }
  }

  componentDidCatch(error = {}, info = {}) {
    datadogLogs.logger.error(`${error.message}, ${info.componentStack}`)
  }

  render() {
    return (
      <div
        style={{ minWidth: '1280px' }}
        className="min-vh-100 flex flex-column"
      >
        <Header />
        <Suspense fallback={<Spinner className="mv4" />}>
          <Router
            component="main"
            role="main"
            className="mh4 ph3 pv4 flex-grow-1"
          >
            <AccountList path="/" />
            <AccountList path="/konto" />
            <Redirect from="/ejendomme/*" to="/konto" noThrow />
            <Redirect from="/regnskaber/*" to="/konto" noThrow />
            <Redirect from="/indstillinger" to="/konto" noThrow />
            <Redirect from="/sudo/:accountId" to="/konto/:accountId" noThrow />
            <AccountEnforcer path="/konto/:accountId">
              <Dashboard path="/" />
              <Properties path="ejendomme" />
              <Property path="ejendomme/:propertyId" />
              <Household path="ejendomme/:propertyId/husstande/:householdId" />
              <Billing path="regnskaber" />
              <AccountPayments path="regnskaber/:statementId/aconto" />
              <Expenses path="regnskaber/:statementId/udgifter" />
              <StatementMessages path="regnskaber/:statementId/beskeder" />
              <BillingReview path="regnskaber/:statementId/gennemse" />
              <UserSettings path="indstillinger" />
              <NotFound default />
            </AccountEnforcer>
            <ResetPassword path="/nulstil-kodeord/:token" />
            <AccountActivation path="/aktivering/:token" />
            <NotFound default />
          </Router>
        </Suspense>
        <Authentication />
        <Footer />
        <TrackPageViews />
      </div>
    )
  }
}

export default App
