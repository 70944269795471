import dayjs from 'dayjs'

export function generateStatement({ startDate, id, relationships = {} }) {
  return {
    attributes: {
      'locked-at': null,
      'start-date': dayjs(startDate).format('YYYY-MM-DD'),
      'end-date': dayjs(startDate)
        .add(1, 'year')
        .subtract(1, 'day')
        .format('YYYY-MM-DD'),
    },
    id,
    relationships,
    type: 'statements',
  }
}

export function generateStatementConfig({ id }) {
  return {
    attributes: {
      'message-all': null,
      'message-owed': null,
      'message-reimbursement': null,
    },
    id,
    type: 'statement-configs',
  }
}
