import range from 'lodash/range'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

dayjs.extend(customParseFormat)
dayjs.extend(isSameOrBefore)

export const DEFAULT_FORMAT = 'DD.MM.YYYY'

export const formatDate = (date, format = DEFAULT_FORMAT, fallback = '–') => {
  if (!date || !dayjs(date).isValid()) {
    return fallback
  }
  return dayjs(date).format(format)
}

export const formatPeriod = (
  dateStart,
  dateEnd,
  format = 'D/M',
  fallback = '–'
) => {
  if (
    !dateStart ||
    !dateEnd ||
    !dayjs(dateStart).isValid() ||
    !dayjs(dateEnd).isValid() ||
    !dayjs(dateStart).isSameOrBefore(dayjs(dateEnd))
  ) {
    return fallback
  }
  if (dayjs(dateStart).isSame(dayjs(dateEnd), 'day')) {
    return dayjs(dateStart).format(format)
  } else {
    return `${dayjs(dateStart).format(format)}–${dayjs(dateEnd).format(format)}`
  }
}

export const formatDateAsISOWithoutOffset = (date) => {
  if (new Date(date).toString() === 'Invalid Date') {
    throw new TypeError('Expected a valid date as input')
  }
  return new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  ).toISOString()
}

export const parseDate = (str) => {
  if (!str || typeof str !== 'string') {
    return undefined
  }
  const strNormalizedDelimiters = str.trim().replace(/\D/g, '.')
  const parsed = dayjs(strNormalizedDelimiters, ['DD.MM.YYYY', 'D.M.YYYY'])
  if (parsed.isValid()) {
    return parsed.toDate()
  }
  return undefined
}

export function eachDay(fromDate, toDate) {
  if (dayjs(toDate).isBefore(dayjs(fromDate))) {
    throw new Error('Expected toDate to be same or after fromDate')
  }
  // Get the diff (with `true`) as a float and round it, to handle DST changes
  const daysDiff = Math.round(dayjs(toDate).diff(fromDate, 'days', true) + 1)
  return range(0, daysDiff).map((n) => dayjs(fromDate).add(n, 'days').toDate())
}
