import React, { useState, useEffect } from 'react'
import * as R from 'ramda'
import PropTypes from 'prop-types'
import { Location } from '@reach/router'
import { Subscribe } from 'bey-fix'
import cx from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import Downshift from 'downshift'
import { datadogLogs } from '@datadog/browser-logs'

import Alert from '../Alert'
import Button from '../Button'
import Input from '../Input'

import accounts, {
  addToAccountList,
  setShowLoginModal,
} from '../state/accounts'
import user from '../state/user'

import { searchAdminAccounts } from '../helpers/api'
import { useDebounce } from '../helpers/hooks'

import arrowDown from '../graphics/arrow-down.svg'

function AccountSelector({
  userName,
  activeApiKey,
  activeSudoAccount,
  accountList = [],
}) {
  const [searchTerm, setSearchTerm] = useState()
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const [searchResults, setSearchResults] = useState([])
  const [errorMessage, setErrorMessage] = useState()

  useEffect(() => {
    async function _searchAdminAccounts() {
      try {
        const { data = [] } = await searchAdminAccounts({
          searchTerm: debouncedSearchTerm,
        })
        const results = data.map((x) => ({
          id: x.id,
          name: x.attributes.name,
          role: x.attributes.role,
        }))
        setSearchResults(results)
      } catch (error) {
        setErrorMessage(error.message)
      }
    }

    if (debouncedSearchTerm && debouncedSearchTerm.length > 2) {
      _searchAdminAccounts()
    } else if (!debouncedSearchTerm || debouncedSearchTerm.length === 0) {
      setSearchResults([])
    }
  }, [debouncedSearchTerm])

  const angelAccount = accountList.find((x) => x.role === 'angel')
  const isAngelUser = Boolean(angelAccount)
  const accountsToShow = accountList.filter((x) => x.role === 'admin')
  const accountsToShowIds = R.pluck('id')(accountsToShow)
  const searchResultsToShow = searchResults.filter(
    (x) => !accountsToShowIds.includes(x.id)
  )

  if (!activeApiKey && (!accountList || accountList.length === 0)) {
    return (
      <Button
        className="db ml4"
        onClick={() => {
          setShowLoginModal(true)
        }}
      >
        Log ind
      </Button>
    )
  }

  return (
    <div className="relative">
      <Location>
        {({ navigate }) => (
          <Downshift
            itemToString={R.or(R.prop('name'), R.toString)}
            selectedItem={
              accountList.find((p) => {
                return activeSudoAccount
                  ? p.id === activeSudoAccount
                  : p.apiKey === activeApiKey
              }) || null
            }
            onChange={(selectedItem) => navigate(`/konto/${selectedItem.id}`)}
          >
            {({
              getLabelProps,
              getToggleButtonProps,
              getMenuProps,
              getItemProps,
              highlightedIndex,
              selectedItem,
              inputValue,
              isOpen,
              closeMenu,
            }) => (
              <div>
                <label className="pl3 flex items-center" {...getLabelProps()}>
                  <span className="moon-gray">
                    Logget ind som {userName} for
                  </span>
                  <button
                    className="tl bn pa1 bg-transparent flex items-center pointer"
                    {...getToggleButtonProps()}
                  >
                    {inputValue || 'Vælg konto'}
                    <img
                      src={arrowDown}
                      alt=""
                      className="ml2"
                      style={{
                        transition: 'transform 0.2s ease-in-out',
                        transform: isOpen ? 'scaleY(-1)' : null,
                      }}
                    />
                  </button>
                </label>
                {isOpen && (
                  <div
                    className="absolute right-0 z-1 mt2 pa3 bg-white ba b--silver br2 shadow-4 w5"
                    style={{
                      top: '100%',
                    }}
                  >
                    {isAngelUser && (
                      <div className="pt1">
                        <Input
                          clearable={true}
                          placeholder="Tilføj via navn"
                          value={searchTerm}
                          onChange={(e) =>
                            setSearchTerm(
                              (e.target.value || '').replace(/ +/g, ' ')
                            )
                          }
                        />
                        {errorMessage && (
                          <Alert type="error" className="mt2">
                            {errorMessage}
                          </Alert>
                        )}
                        {debouncedSearchTerm &&
                          debouncedSearchTerm.length > 2 &&
                          searchResultsToShow.length === 0 && (
                            <p className="tc silver mv3 f7">Ingen resultater</p>
                          )}
                        {debouncedSearchTerm && searchResultsToShow.length > 0 && (
                          <ul className="list mt2 mb0 pl0">
                            <AnimatePresence>
                              {searchResultsToShow.map((account, i) => (
                                <motion.li
                                  key={'search-' + account.id}
                                  positionTransition
                                  initial={false}
                                  animate={{ opacity: 1 }}
                                  exit={{ opacity: 0 }}
                                  className={cx(
                                    'moon-gray pb2 mb2 pointer flex items-center pb2 bb b--silver'
                                  )}
                                  onClick={() =>
                                    addToAccountList({
                                      apiKey: null,
                                      ...account,
                                    })
                                  }
                                >
                                  <span
                                    className="pv1 pr3 flex-grow-1 truncate"
                                    title={account.name}
                                  >
                                    {account.name}
                                  </span>
                                  <Button size="small">Tilføj</Button>
                                </motion.li>
                              ))}
                            </AnimatePresence>
                          </ul>
                        )}
                      </div>
                    )}
                    <ul {...getMenuProps()} className="list mt2 mb0 pl0">
                      <AnimatePresence>
                        {accountsToShow.map((account, i) => (
                          <motion.li
                            key={account.id}
                            positionTransition
                            initial={false}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            {...getItemProps({ item: account })}
                            className={cx(
                              'link pb2 pointer flex items-center',
                              {
                                'pt2 bt b--silver': i > 0,
                                orange: i === highlightedIndex,
                                'fw7 orange': account === selectedItem,
                              }
                            )}
                          >
                            <span
                              className="pv1 pr3 flex-grow-1 truncate"
                              title={account.name}
                            >
                              {account.name}
                            </span>
                            <span className="pv1 silver">{account.id}</span>
                          </motion.li>
                        ))}
                      </AnimatePresence>
                      <li className="pv1 bt b--silver" key="logout">
                        <button
                          className="link bg-transparent bn pointer navy hover-orange db w-100 pv2 ph0 tl pointer"
                          onClick={() => {
                            closeMenu()
                            try {
                              localStorage.removeItem('activeApiKey')
                              localStorage.removeItem('accountList')
                              datadogLogs.logger.info(
                                'AccountSelector.logOut: Cleared localStorage'
                              )
                            } catch (error) {
                              datadogLogs.logger.warn(
                                `AccountSelector.logOut: Clearing localStorage failed - ${error.message}`
                              )
                            }
                            window.location.href = '/'
                          }}
                        >
                          Log ud
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            )}
          </Downshift>
        )}
      </Location>
    </div>
  )
}

AccountSelector.propTypes = {
  userName: PropTypes.string,
  accountList: PropTypes.arrayOf(
    PropTypes.shape({
      apiKey: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
      role: PropTypes.string,
    })
  ),
}

export default function SubscribedAccountSelector(props) {
  return (
    <Subscribe to={user} on={(state) => state.name}>
      {(name) => (
        <Subscribe to={accounts}>
          {(accounts) => (
            <AccountSelector
              userName={name}
              activeApiKey={accounts.activeApiKey}
              activeSudoAccount={accounts.activeSudoAccount}
              accountList={accounts.accountList}
              {...props}
            />
          )}
        </Subscribe>
      )}
    </Subscribe>
  )
}
