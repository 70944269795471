import './polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import GoogleAnalytics from 'react-ga'
import { Datacenter } from '@datadog/browser-core'
import { datadogLogs } from '@datadog/browser-logs'

import App from './App'
import { getVersionString } from './helpers/version'
import './styles/tachyons.css'
import './styles/print.css'

// Service worker disabled for now, there are certain gotchas:
// https://facebook.github.io/create-react-app/docs/making-a-progressive-web-app
// import * as serviceWorker from './serviceWorker'

const { REACT_APP_API_URL, NODE_ENV, REACT_APP_GA_TRACKING_ID } = process.env

// Initialize Datadog logging
datadogLogs.init({
  clientToken: 'pub44c014c6bac78589b320d3cd855d31ff',
  service: 'elrond',
  env: REACT_APP_API_URL.includes('staging') ? 'stag' : 'prod',
  version: getVersionString(),
  datacenter: Datacenter.EU,
  forwardErrorsToLogs: true,
  sampleRate: 100,
})

const isDeployedSite =
  NODE_ENV === 'production' && /wisehome\.dk/.test(window.location.hostname)

datadogLogs.logger.setHandler(isDeployedSite ? 'http' : 'console')
datadogLogs.addLoggerGlobalContext(
  'dimensions',
  `${window.innerWidth}x${window.innerHeight}`
)

GoogleAnalytics.initialize(REACT_APP_GA_TRACKING_ID || 'UA-not-set', {
  titleCase: false,
})
// Only send data to Google Analytics in production, where a tracking ID is set
if (!REACT_APP_GA_TRACKING_ID) {
  GoogleAnalytics.ga('set', 'sendHitTask', null)
}

ReactDOM.render(<App />, document.getElementById('root'))

if (module.hot) {
  module.hot.accept()
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister()
